<template>
  <section>
    <div class="content-header">
      <h2>Building a curious, compassionate, and learning mindset</h2>
    </div>
    <div class="content-wrapper">
      <h3>Engaging with open hearts and minds</h3>
      <p>The individual experiences and topics covered in this section of the toolkit are meant to act as feeding context; situating us in a space where we can learn, un-learn and re-learn. However, we must remember that nothing about this is complete, final, or incontrovertible. </p>
      <p>While historical facts are presented and sourced within each identity pillar, future evidence-based research may shed new light or perspectives on our contemporary understanding of past events. Further to this, the spectrum of unique intersecting identities and emerging experiences that we see around us means that an effort to, even consciously, represent a group of people or an identity will always be tentative and with its gaps.</p>
      <p>For example, as you read about Muslim identity and islamophobia, it is important to note that despite being monotheistic, Islam does have sects. Some examples are Sunni Muslims and Shia Muslims. Even beyond sects, Islam may be practiced and perceived differently by followers with some variations in traditions and day to day practices. This suggests that perhaps the Muslim Pillar may not apply to all Muslims as such but will still provide a greater context on several Muslim identities especially given that insights are provided from members of our Ivey community.</p>
      <p>Similarly, the 2SLGBTQQIA+ identity pillar simply cannot do justice to the many different sexualities or gender identities that exist in our community. Both sexuality and gender identity are private and individual experiences. Yet, these identities shape how students, staff and faculty engage with the community, and in turn how members of the community engage with them. The contents of the pillar speak to several community experiences that may shape our larger understanding of these identities, but may not apply to every member who identifies as a part of the 2SLGBTQQIA+ group.</p>

      <h3>Making meaning from the experiences of others</h3>
      <p>What you will experience in this section of the toolkit is a collection of student voices that are each individual, but meant to contribute towards a larger, incomplete, and yet meaningful sense of intersecting identities and how they occur for members of our classroom communities. You will read about identity pillars, which draw insights from academic research, public policy and government sources, newspaper articles and books. The content of this module was both inspired by Ivey Honours Business Administration (HBA) students and almost entirely written by Ivey HBA students. We are extraordinarily grateful to these students for the dedication of their time, passion and energy.</p>
      <!-- <div class="accordion">
        <accordion
          headerText="Engaging with open hearts and minds"
          accordionID="collapse01"
        >
          <p>The individual experiences and topics covered in this section of the toolkit are meant to act as feeding context; situating us in a space where we can learn, un-learn and re-learn. However, we must remember that nothing about this is complete, final, or incontrovertible. </p>
          <p>While historical facts are presented and sourced within each identity pillar, future evidence-based research may shed new light or perspectives on our contemporary understanding of past events. Further to this, the spectrum of unique intersecting identities and emerging experiences that we see around us means that an effort to, even consciously, represent a group of people or an identity will always be tentative and with its gaps.</p>
          <p>For example, as you read about Muslim identity and islamophobia, it is important to note that despite being monotheistic, Islam does have sects. Some examples are Sunni Muslims and Shia Muslims. Even beyond sects, Islam may be practiced and perceived differently by followers with some variations in traditions and day to day practices. This suggests that perhaps the Muslim Pillar may not apply to all Muslims as such but will still provide a greater context on several Muslim identities especially given that insights are provided from members of our Ivey community.</p>
          <p>Similarly, the 2SLGBTQQIA+ identity pillar simply cannot do justice to the many different sexualities or gender identities that exist in our community. Both sexuality and gender identity are private and individual experiences. Yet, these identities shape how students, staff and faculty engage with the community, and in turn how members of the community engage with them. The contents of the pillar speak to several community experiences that may shape our larger understanding of these identities, but may not apply to every member who identifies as a part of the 2SLGBTQQIA+ group.</p>
        </accordion>
        <accordion
          headerText="Making meaning from the experiences of others"
          accordionID="collapse02"
        >
          <p>What you will experience in this section of the toolkit is a collection of student voices that are each individual, but meant to contribute towards a larger, incomplete, and yet meaningful sense of intersecting identities and how they occur for members of our classroom communities. You will read about identity pillars, which draw insights from academic research, public policy and government sources, newspaper articles and books. The content of this module was both inspired by Ivey Honours Business Administration (HBA) students and almost entirely written by Ivey HBA students. We are extraordinarily grateful to these students for the dedication of their time, passion and energy.</p>
        </accordion>
      </div> -->
    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
